.leaderboard-main{
    text-align: center;
    /* background: linear-gradient(94deg,#ff6601 4.28%,#794444f7 109.53%); */
    color: white;
    font-family: "Poppins";
    margin-top: 50px;
    padding-bottom: 50px;
}

.leaderboard-h1-wrap{
    position: relative;
    height: 50px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.leaderboard-h1{
    position: absolute;
    top: -30px;
    background: linear-gradient(94deg,#ff6601 4.28%,#794444f7 109.53%);
    color: white;
    padding: 15px;
    border-radius: 15px;
    font-size: 20px;
    box-shadow: 0px 5px 7px 2px black;
}
.leaderboard-list {
    list-style-type: none;
    padding: 0;
    margin: 0 auto;
    width: 90vw;
    max-width: 600px;
    min-width: 270px;
}

.leaderboard-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    margin-bottom: 10px;
}
.rank{
    width: 50px;
    height: 50px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.rank-info{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 50px;
    /* border: 3px solid #f9a215; */
    border-radius: 15px;
    background: linear-gradient(94deg,#ff6601 4.28%,#794444f7 109.53%);
    color: white;
}

.rank-icon {
    width: 40px;
}

.rank-number {
    font-size: 1.2em;
    font-weight: bold;
}

.name {
    flex: 1;
    text-align: left;
    padding-left: 10px;
}

.number {
    flex: 1;
    text-align: center;
    /* padding-left: 10px; */
}

.score {
    flex: 1;
    /* padding-left: 10px; */
    text-align: center;
}

.glow-border {
    position: relative;
    overflow: hidden;
}

.glow-border::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 2px solid transparent;
    box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
    animation: glow 2s linear infinite;
}

.not-loaded{
    width: 70%;
    min-width: 300px;
    max-width: 600px;
    margin: 0 auto;
    font-size: 24px;
    font-family: "Poppins";
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes glow {
    0% {
        border-color: rgba(255, 255, 255, 0);
        box-shadow: 0 0 10px rgba(255, 255, 255, 0);
    }
    25% {
        border-color: #f9a215;
        box-shadow: 0 0 20px #f9a215;
    }
    50% {
        border-color: rgba(255, 255, 255, 0);
        box-shadow: 0 0 10px rgba(255, 255, 255, 0);
    }
    75% {
        border-color: #f9a215;
        box-shadow: 0 0 20px #f9a215;
    }
    100% {
        border-color: rgba(255, 255, 255, 0);
        box-shadow: 0 0 10px rgba(255, 255, 255, 0);
    }
}
