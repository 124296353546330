.footer{
    position: relative;
    bottom: 0;
    width: 100%;
    padding-top: 70px;
    text-align: center;
    color: var(--ios-home-indicator-background-color, #FFF);
    font-family: 'Poppins';

}

.footer .mobile-social-links{
    display: none;
}

.footer .footer-social-links {
    padding-right: 4%;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%;
    text-align: right;
}

.footer .footer-social-links .follow-text{
    padding-right: 10px;
}

.footer .footer-social-links img {
    height: 18px
}
.footer .footer-left {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%;
    text-align: left;
}

.footer-logo img {
    width: 100%;
}

@media (max-width: 767px) {
    .footer .desktop-social-links{
        display: none;
    }

    .footer .mobile-social-links{
        display: block;
    }

    .footer .footer-logo{
        text-align: left;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 133%;
        text-align: left;

    }

    .footer .footer-logo img {
        width:150px;
        padding-left: 13px;
    }

    .footer .footer-left {
        text-align: left;
        font-size: 10px;
        font-style: normal;
        font-weight: 400;
        line-height: 133%;
        padding-left: 44px;
    }
}

