body.modal-open {
    position: fixed;
    width: 100%;
}
html.modal-open,
body.modal-open {
    height: 100%;
    overflow: hidden;
}
.pusher-notify-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0 0 0 / 78%);
    overflow: hidden;
    z-index: 9999;
}
.pusher-notification {
    position: relative;
    width: 100%;
    max-width: 1200px;
    border-radius: 25px;
    overflow: hidden;
    z-index: 10000;
}
.pusher-notification-img {
    width: 100%;
    height: auto;
    border: none;
    user-select: none;
}
.notify-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 40px;
    color: #ee630b;
    cursor: pointer;
    outline: none;
    z-index: 10001;
}
body.modal-open {
    overflow: hidden;
}
