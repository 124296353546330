.section-card{
    height: auto;
    margin-bottom: 50px;
}
.section-card-tournament{
    height: auto;
  /* margin-top: 100px; */
}
.cards-wrapper {
    padding: 0 0px 0 70px;
    font-family: Poppins;
}
.tournament-total-participant{
    color: #fff;
    margin-left: 7px;
    line-height: 0px;
    margin-top: -5px;
}
.card-slider-wrapper p.main-slider-heading {
    color: #FFF;
    font-size: 1.1rem;
    font-style: normal;
    margin-top: -7px;
}
.comming-soon-slider-tournament{
    margin-top: 26px;
}
.play-now-card-slider-btn{
    margin-top: 20px;
}
.play-now-card-slider-btn .play-btn{
    display: flex;
    color: #fff !important;
    width:  134.78px;
    height: 34.619px;
    font-size: 14.167px;;
    padding: 22.023px;
    justify-content: center;
    align-items: center;
    gap: 13.023px;
    top: -20px;
    color: #FFF;
    border-radius: 17.837px;
    flex-shrink: 0;
    float: right;
    background: linear-gradient(94deg, #D83335 4.28%, rgba(121, 68, 68, 0.97) 109.53%);
}
.comming-soon-slider-tournament p{
    text-align: right;
    font-weight: 400;
    font-size: 14px;
    line-height: 20%;
    color: #818181;
}
.cards-wrapper .cards-round {
    border-radius: 45.38px;
    background-color: #1F222A;
    padding: 18.2px;
    height: auto;
}

.cards-wrapper .slider .slick-prev {
    left: -13px;
}

.tournaments {
    /* margin-bottom: -370px; */
    margin-bottom: 0;
}

.cards-wrapper .cards-round img {
    background: linear-gradient(94deg, #D83335 0%, rgba(120.91, 68.01, 68.01, 0.97) 100%);
    border-radius: 45.38px;
}

.cards-wrapper .slider .slick-list .slick-track .slick-active {
    position: relative;
}

.cards-wrapper .text-white {
    color: #fff !important
}

.cards-wrapper .errow-icon {
    display: none;
}

.cards-wrapper .headingz {
    font-size: 37.495px;
    font-weight: 600;
    color: var(--primary) !important;
}
.cards-wrapper .cards-round .card-img{
    height: auto;
    width: 100%;
}

.cards-wrapper .cards-round .cards-info {
    padding-top: 10px;
}

.cards-wrapper .card-slider-heading {
    padding: 60px 0 30px 0;
}

.cards-wrapper .cards-round .cards-info .cards-label {
    font-size: 25.879px;
    margin-left: 6px;
    margin-top: 3px;
    letter-spacing: 1.294px;

}

.cards-wrapper .slider.slider-gap .slick-slide {
    margin: 0px 7px;
}

.cards-wrapper .slider.arrow-light .slick-prev, .cards-wrapper .slider.arrow-light .slick-next {
    top: 50%;
}
.gaming-tasks-slider-wrapper .slider.arrow-light .slick-prev, .gaming-tasks-slider-wrapper .slider.arrow-light .slick-next {
    top: 23%;
}
.play-game-slider .slider.arrow-light .slick-prev,.play-game-slider .slider.arrow-light .slick-next {
    top: 50%;
}
.cardSlider-heading-tournament {
    color: #F2BC1A;
    margin-left: 6px;
    margin-top: -10px;
}
@media (max-width: 992px) {
    .tournaments {
        margin-bottom: 0;
    }
    .cards-wrapper {
        padding: 0 28px 0 25px;
    }
    .cardSlider-heading-tournament{
        font-size: 13px;
        margin-top: -3px;
    }
    .play-now-card-slider-btn .play-btn{
        width: 95px;
        font-size: 11px;;
    }
    .card-slider-wrapper p.main-slider-heading {
        color: #FFF;
        font-size: 1.1rem;
        font-style: normal;
        margin-top: -7px;
    }
    .comming-soon-slider-tournament p{
        font-size: 12px;
    }
    .cards-wrapper .slider.slider-gap .slick-slide {
        margin: 0;
    }
    .cards-wrapper .card-slider-heading {
        padding: 30px 0 30px 0;
    }
    .card-slider-wrapper .slick-slide{
        width: 266px !important;
    }

    .cards-wrapper .cards-round {
        border-radius: 25.38px;
        width: 250px !important;
    }

    .cards-wrapper .cards-round .cards-info .cards-label {
        font-size: 15.879px;
        margin-top: 8px;
        letter-spacing: 1.294px;

    }

    .cards-wrapper .headingz {
        color: var(--primary) !important;
        font-size: 24px;
    }

    .cards-wrapper .cards-round img {
        background: linear-gradient(94deg, #D83335 0%, rgba(120.91, 68.01, 68.01, 0.97) 100%);
        border-radius: 25.38px;
    }

    .cards-wrapper .errow-icon {
        display: block;
        overflow: hidden;
        vertical-align: middle;
        text-align: right;
        float: right;
        padding-right: 15px;
    }
    .cards-wrapper .cards-round .card-img{
        height: auto;
        width: 100%;
    }
    .cards-wrapper .slider .slick-list .slick-track .slick-slide:not(.slick-active) {
        opacity: unset
    }
}