.cards-round {
    border-radius: 30.38px;
    background-color: #1F222A;
    padding: 10.2px;
}
.submit-entry-card{
    margin-top: 10px;
}
.submit-entry-card .your-credit{
    margin-top: 5px;
}
.submit-entry-subheading{
    color: #FFF;
    font-family: Poppins;
    font-size: 16px;
    margin-top: -5px;
}
.submit-entry-wrapper{
    margin-top: 50px;
}
.cards-wrapper {
    padding: 0px 0px 0 80px;
}
/* Submit entry */
.game-heading{
    color: #FFF;
    font-size: 26px;
    font-weight: 600;
}
.take-scewwnshot{
    color: #FFF;
    margin-top: 10px;
    font-family: Poppins;
    font-size: 16px;
}
.text-red{
    color:red !important;
}
.text-green{
    color:green !important;
}
.submit-entry-img{
    border-radius: 33px;
    height: 406px;
    width: 680px;
}
.cards-challenge-round {
    border-radius: 21.38px;
    background-color: #1F222A;
    padding: 11.2px;
    margin-top: 30px;
}

.daily-gaming-task{
    color: #F2BC1A;
    font-family: Orbitron;
    font-size: 41.495px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%; /* 52.493px */
    letter-spacing: 1.875px;
    padding-left: 5px;
}
#slider-card{
    padding: 15px 0px 15px 70px;
    margin-right: -60px;
}
.slider-card-heading{
    color: var(--ios-home-indicator-background-color, #FFF);
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 1.3px;
    padding-left: 10px;
    margin-bottom: 30px;
}

.slider-card-col{
    border-radius: 45.38px;
    background-color: #1F222A;
}
.img-padding{
    padding: 18.2px;
}
.slider-card-col img{
    background: linear-gradient(94deg,#ff6601 4.28%,#794444f7 109.53%);;
    border-radius: 45.38px;
}


.card-heading-00{
    margin-top: -20px;
    color: #fff;
    font-family: Poppins;
    font-size: 16.167px;
    font-style: normal;
    font-weight: 400;
    padding-top: 15px;
    line-height: 140%; /* 31.034px */
    letter-spacing: 0.246px;

}
.challenge-info-heading{
    padding-left: 10px;
    margin-top: 16px;
    color: white;
}
.card-heading-01{
    margin-top: -25px;
    color: #F2BC1A;
    font-family: Poppins;
    font-size: 12.167px;
    font-style: normal;
    font-weight: 400;
    padding-top: 15px;
    line-height: 140%; /* 31.034px */
    letter-spacing: 0.246px;width: 276.215px;
}

.card-heading-challenge{
    margin-top: -7px;
    color: #F2BC1A !important;
    font-family: Poppins;
    font-size: 12.167px;
    font-style: normal;
    font-weight: 400;
    padding-top: 15px;
    line-height: 140%; /* 31.034px */
    letter-spacing: 0.246px;
    width: 276.215px;
}

.complete-challenge-card-heading{
    margin-top: -12px;
    color: #fff !important;
    font-family: Poppins;
    font-size: 16.167px;
    font-style: normal;
    font-weight: 400;
    padding-top: 15px;
    line-height: 140%; /* 31.034px */
    letter-spacing: 0.246px;

}

.submit-entry-btn{
    margin-top: -12px;
    display: flex;
    width: 104.78px;
    height: 34.619px;
    font-size: 12.167px;
    padding: 3.023px;
    justify-content: center;
    align-items: center;
    gap: 13.023px;
    top: -20px;
    color: white !important;
    border-radius: 11.837px;
    flex-shrink: 0;
    float: right;
    background: linear-gradient(94deg, #D83335 4.28%, rgba(121, 68, 68, 0.97) 109.53%);
}

.cards-tournament-round img {
    background: linear-gradient(94deg,#ff6601 4.28%,#794444f7 109.53%);;
    border-radius: 21.38px;
    width: 100%;
    height: 280px;
}
.gaming-challenges .cards-tournament-round img {
    border-radius: 21.38px;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
}
.card-image-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(94deg,#ff6601 4.28%,#794444f7 109.53%);
    height: 280px;
    border-radius: 21.38px;
    overflow: hidden;
}
.choose-file-text {
    color: #D9D9D9;
    text-align: right;
    font-size: 15.537px;
    letter-spacing: 0.777px;
    margin-top: 30px;
    margin-bottom: 0;
}

/* Style for the file input button */
.file-input-button {
    width: 380px;
    padding: 17.5px;
    background: linear-gradient(94deg, #D83335 4.28%, rgba(121, 68, 68, 0.97) 109.53%);
    font-size: 22.041px;
    color: #fff;
    text-align: center;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    float: right;
    margin-right: -270px;
}

/* Style for the actual file input (opacity set to 0 to make it invisible) */
.file-input {

    opacity: 0;
    cursor: pointer;
    float: right;
}
.choose-a-file {
    margin-top: 16px;
    display: flex;
    height: 60.619px;
    font-size: 12.167px;
    padding: 3.023px;
    justify-content: center;
    align-items: center;
    gap: 13.023px;
    top: -20px;
    color: white !important;
    border-radius: 11.837px;
    flex-shrink: 0;
    float: right;

}
.arrow-steps-tournment-p{
    color: #FFF;
    font-family: Poppins;
    font-size: 44px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 61.6px */
    letter-spacing: 2.2px;
    margin-top: 195px;
    margin-left: 19px;
}
.your-credit-challeneg{
    color: #818181;
    font-family: Poppins;
    font-size: 17.625px;
    font-style: normal;
    font-weight: 400;
    text-align: right !important;
    line-height: 120%;
    margin: 0;
}

.your-credit-challeneg-count{
    color: #F2BC1A;
    font-family: Poppins;
    font-size: 29.375px;
    font-style: normal;
    font-weight: 400;
    text-align: right !important;
    line-height: 120%;
    margin: 5px 0 0 0;
}

@media (max-width: 992px) {
    .submit-entry-img{
        height: 240px;
        min-width: 260px;
        width: 100%;
    }
    .cards-wrapper {
        padding: 0px 0px 0 32px;
    }
    .cards-tournament-round img {
        background: linear-gradient(94deg,#ff6601 4.28%,#794444f7 109.53%);;
        border-radius: 30.38px;
        width: 100%;
        height: 215px;
    }
    .card-image-wrapper {
        height: 215px;
    }
    .site-footer {
        padding-top: 26px;
    }

    .arrow-steps-tournment{
        display: none;
    }
    .file-input-button {
        width: 100%;
        padding: 10px;
        background: linear-gradient(94deg,#ff6601 4.28%,#794444f7 109.53%);
        color: #fff;
        text-align: center;
        border: none;
        border-radius: 12px;
        cursor: pointer;
        float: right;
        margin-right: -270px;
    }
    .choose-a-file {
        width: 100%;
        margin-top: 16px;
        display: flex;
        height: 60.619px;
        font-size: 12.167px;
        padding: 3.023px;
        justify-content: center;
        align-items: center;
        gap: 13.023px;
        top: -20px;
        color: white !important;
        border-radius: 11.837px;
        flex-shrink: 0;
        float: right;

    }

    .submit-entry-btn{
        margin-top: -10px;
        display: flex;
        width: 90.78px;
        height: 30.619px;
        font-size: 12.167px;
        padding: 3.023px;
        justify-content: center;
        align-items: center;
        gap: 13.023px;
        top: -20px;
        color: white !important;
        border-radius: 11.837px;
        flex-shrink: 0;
        float: right;
        background: linear-gradient(94deg, #D83335 4.28%, rgba(121, 68, 68, 0.97) 109.53%);
    }


}