.notification-modal-content .modal-body {
    padding: 0 35px 38px;
}

.notification-modal-content .modal-header{
    border-bottom: none;
    padding: 33px 38.9px 18px 35px;
}

.notification-modal {
    position: fixed;
    top: 72px;
    left: 297px;
    z-index: 1060;
    display: none;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
}

.notification-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    border-radius: 0px;
    background: #060606;
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    outline: 0;
    text-align: center;
    justify-content: center;
}

@media (min-width: 992px){
    .notification-modal-dialog {
        max-width: 604px;
        margin: 1.75rem auto;
    }
}

.notification-modal-title{
    color: #F2BC1A !important;
}

.notification-text {
    color:white;display: flex;
    width: 528px;
    padding: 24px;
    align-items: center;
    gap: 16px;border-radius: 20px;
    background: var(--dark-dark-2, #1F222A);
    box-shadow: 0px 4px 60px 0px rgba(4, 6, 15, 0.05);
    margin-top:5px;
}

.notification-img{
    width: 24px !important;
    height: 24px !important;
    flex-shrink: 0;
    position: absolute;
    right: 68px;
}
