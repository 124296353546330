.cards-wrapper-tournament {
    padding: 0px 94px 0 70px;
}

.chalenges-body{
    max-width: 1200px;
    margin: auto;
}

.tournament-body{
    max-width: 1200px;
    margin: auto;
}

.tournament-page-total-participant{
    color: #fff;
    margin-left: 2px;
    line-height: 50px;
}
.cards-tournament-round {
    border-radius: 40px;
    background-color: #1F222A;
    padding: 17.74px;
    margin-bottom: 30px;
    font-family: Poppins;
}
.tournament-wrapper .cards-tournament-round {
    border-radius: 40px;
    background-color: #1F222A;
    padding: 17.74px 17.74px 0 17.74px;
    margin-bottom: 30px;
    font-family: Poppins;
    height: auto;
}

.tournament-heading .headingz {
    color: var(--primary) !important;
    font-size: 37.495px;
    font-family: 'Orbitron';
}

.cards-tournament-round img {
    background: linear-gradient(94deg, #D83335 0%, rgba(120.91, 68.01, 68.01, 0.97) 100%);
    border-radius: 40px;
    width: 100%;
    height: 253px;
}

.cards-tournament-round .challenges_img{
    background: linear-gradient(94deg, #D83335 0%, rgba(120.91, 68.01, 68.01, 0.97) 100%);
    border-radius: 40px;
    width: 100%;
    height: 400px;
}

.cards-tournament-round .tournament_img{
    background: linear-gradient(94deg, #D83335 0%, rgba(120.91, 68.01, 68.01, 0.97) 100%);
    border-radius: 40px;
    width: 100%;
    height: 400px;
}

.comming-soon-tournament {
    margin-top: 10px !important;
}
.comming-soon-tournament p {
    text-align: right;
    font-weight: 400;
    font-size: 14px;
    line-height: 20%;
    color: #818181;
}
.tournament-heading-wrapper{
    margin-top: -10px;
}
.cards-tournament-round .play-btn {
    display: flex;
    color: #fff !important;
    width: 134.78px;
    height: 34.619px;
    font-size: 14.167px;
    padding: 22.023px;
    justify-content: center;
    align-items: center;
    gap: 13.023px;
    top: -20px;
    color: #FFF;
    border-radius: 17.837px;
    flex-shrink: 0;
    float: right;
    background: linear-gradient(94deg,#ff6601 4.28%,#794444f7 109.53%);
}
.cards-tournament-round .submit-again {
    display: flex;
    width: 140px;
    height: 34.619px;
    font-size: 14.167px;
    padding: 22.023px;
    justify-content: center;
    align-items: center;
    gap: 13.023px;
    top: -20px;
    color: #FFF;
    border-radius: 17.837px;
    flex-shrink: 0;
    float: right;
    background: linear-gradient(94deg,#ff6601 4.28%,#794444f7 109.53%);
}
.cards-tournament-round .tournament-card-info {
    margin: 20px 0 30px 0;
}

.cards-tournament-round .tournament-info-heading {
    font-size: 25px;
    color: #FFF;
}

.card-heading-tournament {
    color: var(--primary) !important;
    /* font-size: 19px; */
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0.246px;
    margin: 2.25px 0 0 0;
}

.tournament-heading .tournament-sub-heading {
    color: #fff !important;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    margin-top: -15px;
    line-height: 140%;
    letter-spacing: 0.246px;
    margin-left: 6px;
}
.no-data-found{
    color: #FFF;
    font-size: 22px;
    font-style: normal;
    margin: 64px 0px 0px 0px;
}

.cards{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 33%;
}

@media (max-width: 1100px){
    .cards{
        width: 49%;
    }
}

@media (max-width: 736px){
    .cards{
        margin: auto;
        width: 51%;
    }
}

@media (max-width: 700px){
    .cards{
        width: 61%;
    }
}

@media (max-width: 650px){
    .cards{
        width: 81%;
    }
}

@media (max-width: 500px){
    .cards{
        width: 91%;
    }
}

@media (max-width: 450px){
    .cards{
        width: 100%;
    }
}

@media (max-width: 1000px) {
    .cards-wrapper-tournament {
        padding: 0px 35px 0 35px;
    }

    

    .cards-tournament-round .tournament-info-heading {
        font-size: 25px;
        color: #FFF;
        line-height: 120%;
    }

    .cards-tournament-round {
        border-radius: 40px;
    }

    .cards-tournament-round img {
        border-radius: 30px;
        height: 200px;
    }
}

@media (max-width: 460px) {
    .card-heading-tournament {
        font-size: 16px;
        line-height: 100%;
    }
    .cards-tournament-round .tournament-info-heading {
        font-size: 20px;
    }
    .comming-soon-tournament p {
        font-size: 12px;
    }
    .tournament-page-total-participant{
        line-height: 40px;
    }

    
}
@media (max-width: 350px) {
    .comming-soon-tournament p {
        font-size: 10px;
    }
    .tournament-page-total-participant{
        line-height: 40px;
    }
}

@media (max-width: 390px) {
    .cards-tournament-round .tournament_img{
        height: auto;
    }

    .cards-tournament-round .challenges_img{
        height: auto;
    }
}

