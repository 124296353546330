/* loader.css */
.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Adjust height as needed */
}
.loader-redeem{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    position: fixed;
    z-index: 9999;
    left: 0;
    right: 0;
    background: #1a1a1a;
    top: 0;
}