.form_main{
    background: #f1ba1f;
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 35px;
}
.formSection{
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    /* margin-top: 150px; */
    transition: margin-top 0.25s ease-in;
}
.formImg{
    position: fixed;
    height: 100%;
    width: 100%;
    z-index: -1;
}
.formSectionh3{
    color: black;
    font-family: open_sans_bold !important;
    font-size: 36px;
}
.formSectionp1{
    line-height: 1.3;
    text-align: center;
    font-size: 17px;
    color: black;

}
.formSectionp2{
    color: black;
    font-family: open_sans_bold !important;
    margin: 10px 0;
    text-align: center;
    font-size: 17px;
}
.formSectionp3{
    color: black;
    font-size: 17px;

}
.RedeemForm{
    display: flex;
    flex-direction: column;
}
.formWrap{
    margin-top: 30px;
    width: 310px;
}
.mtb{
    margin: 15px 0;
}
.formInput{
    background: transparent;
    border: 1px solid black;
    border-radius: 5px;
    height: 40px;
    width: 100%;
    color: black;
    text-align: left;
    padding: 5px;
}
.RedeemFormBtn{
    /* background: var(--bs-body-color); */
    background: linear-gradient(94deg, #D83335 4.28%, rgba(121, 68, 68, 0.97) 109.53%);
    color: #FFCA0A;
    border: none;
    height: 40px;
    width: 100%;
    border-radius: 25px;
    margin: 0 auto;
    margin-top: 15px;
    box-shadow: 0 3px 2px 0 #666;
    transition: .2s ease;
}
.RedeemFormBtn:active{
    transform: translateY(3px);
    box-shadow: none;
}

.insufficentCoins{
    width: 250px;
    display: flex;
    margin: 0 auto;
    box-shadow: 0 0 5px 0 #3d3c3a;
    height: 30px;
    align-items: center;
    background: #3d3c3a;
    position: absolute;
    top: 90px;
    transition: opacity .3s ease-out;
    
}
.insufficentCoins p {
    font-family: "outfitBold";
    background: #3d3c3a;
    width: 100%;
    text-align: center;
}
.hidden{
    opacity: 0;
}
.show{
    opacity: 1;
}
.redeembtn{
    /* background: var(--bs-body-color); */
    background: linear-gradient(94deg, #D83335 4.28%, rgba(121, 68, 68, 0.97) 109.53%);
    color: white;
    border: none;
    height: 40px;
    width: 80%;
    border-radius: 25px;
    margin: 0 auto;
    margin-top: 0px;
    margin-top: 15px;
    box-shadow: 0 3px 2px 0 #666;
    transition: .2s ease;
}
.redeembtn:active{
    transform: translateY(3px);
    box-shadow: none;
}
/* 
@media screen and (min-height: 633px) {
    .formSection{
        margin-top: 150px
    }
  }

  @media screen and (min-height: 721px) {
    .formSection{
        margin-top: 170px
    }
  } */