.profile-header{
    display: none;
}
.arrow-back{
    width:30px;
 }
 .cards-wrapper-profile {
    padding: 0px 94px 0 70px;
 }

.header-profile-title{
    color: #F2BC1A;
    font-family: Poppins;
    font-size: 21.28px;
    font-style: normal;
    font-weight: 600;
    padding-left: 10px;
    line-height: 133%; /* 28.302px */
}
.container {
  position: relative;
  display: inline-block;
}

 .user-profile-image{
     width: 50px;
     height: 50px;
     border-radius: 50px;
     margin-left: 20px;
     margin-top: 22px;
 }
.profile-content {
    position: relative;
    display: flex;
    align-items: center;
    margin-left: 15px;
    margin-top: -17px;
}

.profile-edit {
  position: absolute;
  top: 50%;
  left: 100%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  width: 35.776px;
  height: 34.542px;
  flex-shrink: 0;
}

.profile-userid{
    color: var(--ios-home-indicator-background-color, #FFF);
    font-family: Poppins;
    font-size: 18.219px;
    font-style: normal;
    font-weight: 300;
    line-height: 133%;
    
}
.profile-username{
    color: var(--ios-home-indicator-background-color, #FFF);
    font-family: Poppins;
    font-size: 32.794px;
    font-style: normal;
    font-weight: 400;
    margin: 0;
    line-height: 133%;
   
}
.user-stats{

    /* display: flex; */
    /* width: 873px; */
    padding: 22.36px 44.72px 22.36px 55.154px;
    /* padding: 20.36px 2.72px 5.36px 26.154px; */
    justify-content: center;
    align-items: center;
    gap: 14.907px;
    margin-top: 30px;
    border-radius: 14.907px;
    border: 1.491px solid #D9D9D9;
    background: rgba(129, 129, 129, 0.36);
}
.user-score{
color: #F2BC1A;
font-family: Poppins;
font-size: 26.794px;
font-style: normal;
font-weight: 600;

}

.user-score-detail{
color: var(--ios-home-indicator-background-color, #FFF);
font-family: Poppins;
font-size: 20.64px;
font-style: normal;
font-weight: 400;
margin: 0;

}
.bio-detail{
    color: var(--ios-home-indicator-background-color, #FFF);
    font-family: Poppins;
    font-size: 21.454px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%;
    margin: 0;
}
.user-bio{
    padding-top: 30px;
   
}
.bio{
    color: #F2BC1A;
    line-height: 133%;
}
.bio-edit {
float: right;
left: 100%;
color: white;
text-align: center;
width: 35.776px;
height: 34.542px;
flex-shrink: 0;
}

.background-profile{
    background-image: url('../images/profile/profile-background.png');
    background-repeat: no-repeat;
    background-position: center left;
    padding-bottom: 23px;width: 100px;
}
.profile-headingz{
    font-size: 37.495px;
    font-weight: 600;
    color: #F2BC1A !important;
}
/* input edit*/
.profile-username-input{
    position: relative;;
    background-color : rgba(129, 129, 129, 0.36);
    border-radius:10px;
    width:280px;
    height:40px;
    border:none;
    font-size: 15px;
    color: #FFF;
    padding: 10px;
    border: 1.491px solid #D9D9D9;
}
.profile-tick{
    width: 38px;
    filter: invert(57%) sepia(89%) saturate(379%) hue-rotate(8deg) brightness(106%) contrast(106%);
}
.bio-detail-textarea{
    width: 100%;
    background-color: rgba(129, 129, 129, 0.36);
    border-radius: 14.907px;
    color: #FFF;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 133%;
    border: 1.491px solid #D9D9D9;
    padding: 15px;
    
    margin-top: 30px;
}
.camera-overlay {
    position: absolute;
    top: 0;
    left: -40%;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
}
.camera-overlay:hover {
    opacity: 1;
}
.camera-icon img {
    width: 30px; /* Adjust icon size */
    height: auto;
    filter: invert(57%) sepia(89%) saturate(379%) hue-rotate(8deg) brightness(106%) contrast(106%);
}
.chooseProfile-file-text{
    margin-left: 21px;
}
.logout-icon{
    filter: brightness(0) saturate(100%) invert(100%) sepia(0%)
}
@media (max-width: 992px) {
    .profile-username-input{
        width:180px;
    }
    .camera-overlay {
        opacity: 1;
        left: -37%;
    }
    .profile-headingz{
    display: none;
}
    .profile-header{
        display: block;
    }

.user-score-detail{
color: var(--ios-home-indicator-background-color, #FFF);
font-family: Poppins;
font-size: 12.64px;
font-style: normal;
font-weight: 400;
}

.profile-header-logo{
    margin-right: -150px;
}

.profile-username {
    font-size: 21.794px;;
}
.profile-userid {
    font-size: 12.219px;
}

.profile-edit {
  position: absolute;
  top: 50%;
  left: 94%;
  transform: translate(-50%, -50%);
  color: white;
  text-align: center;
  width: 35.776px;
  height: 34.542px;
  flex-shrink: 0;
}

    .cards-wrapper-profile {
    padding: 0px 29px 0 16px;

    }

    .user-bio{
    padding-top: 80px;
   
}
.bio{
    padding-left: 24px;
    color: #F2BC1A;
}

 .bio-edit{
    margin-right: -15px;
 }

.user-stats{
    padding: 20.36px 2.72px 5.36px 26.154px; 
 }

}