/* Slider Start */
@font-face {
    font-family: 'themify';
    src: url("../fonts/themify.eot");
    src: url("../fonts/themify.eot") format("embedded-opentype"), url("../fonts/themify.woff") format("woff"), url("../fonts/themify.ttf") format("truetype"), url("../fonts/themify.svg") format("svg");
    font-weight: normal;
    font-style: normal
}

.slider .slider-img {
    border-radius: 49.046px;
    background: #FFF;
    position: relative;
}

.banner-view {
    position: relative; /* Relative positioning for the parent container */
    max-width: 100%; /* Ensure it doesn't exceed screen width */
    padding: 0 70px 0 70px;
}

.slider slider-mob {
    padding: 12px;
}

.banner-text {
    position: absolute;
    margin-top: 60px;
    left: 50%;
    transform: translateX(-50%);
    text-align: center;
    width: 100%;
    z-index: 2; /* Ensure it's above the slider */
    color: white; /* Text color */
    color: #F2BC1A;
    font-family: Orbitron;
    font-size: 46.997px;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 5.3px;
}


.slick-slider {
    position: relative;
    display: block;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: none
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-track, .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.slick-track:before, .slick-track:after {
    content: "";
    display: table
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none
}

[dir="rtl"] .slick-slide {
    float: right
}

.slick-slide img {
    display: block;
    width: 100%
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

.slider {
    position: relative;
    letter-spacing: 0;
    cursor: -webkit-grab;
    cursor: grab
}

.cards-wrapper .slider .slick-list .slick-track .slick-active {
    position: relative;
}

.slider.rounded, .slider.rounded-lg, .slider.rounded-sm {
    overflow: hidden
}

.slider .slick-dots {
    position: absolute;
    bottom: -35px;
    list-style: none;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0;
    margin: 0;
    width: 100%
}

.slider .slick-dots li {
    margin: 5px;
    line-height: 1px;
    cursor: pointer
}

.slider .slick-dots li button {
    padding: 0;
    background: #343a40;
    height: 6px;
    width: 6px;
    opacity: 0.5;
    border-radius: 10rem;
    font-size: 0;
    cursor: pointer;
    -webkit-transition: 0.3s ease;
    -o-transition: 0.3s ease;
    transition: 0.3s ease;
    border: unset
}

.slider .slick-dots li button:hover {
    outline: none;
    opacity: 0.7
}

.slider .slick-dots li.slick-active button {
    opacity: 1
}

.slider.with-dots-inside {
    margin-bottom: 0
}

.slider.with-dots-inside .slick-dots {
    bottom: 1rem;
    opacity: 0.8
}

.slider.line-dots .slick-dots li button {
    border-radius: 10px;
    height: 5px;
    width: 5px
}

.slider.line-dots .slick-dots li.slick-active button {
    opacity: 1;
    width: 20px
}

.slider .slick-prev, .slider .slick-next {
    position: absolute;
    display: block;
    width: 32px;
    height: 32px;
    border-radius: 7px;
    line-height: 0px;
    font-size: 0px;
    cursor: pointer;
    top: 50%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    padding: 0;
    border: none;
    outline: none;
    background: rgba(217, 217, 217, 0.50);
    -webkit-transition: 0.3s ease-out;
    -o-transition: 0.3s ease-out;
    transition: 0.3s ease-out;
    z-index: 1;
    font-family: "themify"
}

.slider .slick-prev {
    left: 80px;
    color: #9E1F32;
    font-weight: bold;
}

.sliderz .slick-prev {
    color: #9E1F32 !important;
    font-weight: bold;
}

.slider .slick-prev:before {
    content: "\e64a";
    display: block;
    margin: 0 auto;
    font-size: 17px
}

.sub-sliders {
    width: 300px !important;
}

.slider .slick-next {
    right: 80px;
    color: #9E1F32 !important;
    font-weight: bold;
}

.sliderz .slick-next {
    right: 90px !important;
    color: #9E1F32;
    font-weight: bold;
}

.slider .slick-next:before {
    content: "\e649";
    display: block;
    margin: 0 auto;
    font-size: 17px
}

.slider.arrow-circle .slick-prev, .slider.arrow-circle .slick-next {
    border-radius: 50%;
    height: 48px;
    width: 48px
}

.slider.arrow-outside {
    padding: 0 51.2px
}

.slider.arrow-autohide .slick-prev, .slider.arrow-autohide .slick-next {
    opacity: 0
}

.slider.arrow-autohide .slick-prev {
    left: -5px
}

.slider.arrow-autohide .slick-next {
    right: -5px
}

.slider.arrow-autohide:hover .slick-prev, .slider.arrow-autohide:hover .slick-next {
    opacity: 1
}

.slider.arrow-autohide:hover .slick-arrow.slick-prev {
    left: 16px
}

.slider.arrow-autohide:hover .slick-arrow.slick-next {
    right: 16px
}

.slider.slider-center-focused .slick-list {
    padding-top: 16px !important;
    padding-bottom: 16px !important
}

.slider.slider-center-focused .slick-slide {
    opacity: 0.2;
    -webkit-transition: 0.5s ease-out;
    -o-transition: 0.5s ease-out;
    transition: 0.5s ease-out;
    text-align: center
}

.slider.slider-center-focused .slick-slide img {
    -webkit-transition: 0.5s ease-out;
    -o-transition: 0.5s ease-out;
    transition: 0.5s ease-out
}

.slider.slider-center-focused .slick-center {
    opacity: 1
}

.slider.slider-center-focused .slick-center img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1)
}

.banner-view .slider.slider-gap .slick-slide {
    margin: 0px 7px !important;
}


.slider.slider-gap-lg .slick-slide {
    padding: 0 51.2px
}

.slider.arrow-light .slick-prev, .slider.arrow-light .slick-next {
    /* background-color: #fff !important; */
    /* color: #4E97FD !important; */
    border: 0px solid #edf2f9;
    -webkit-box-shadow: 0 5px 2px rgba(52, 58, 64, 0.2);
    box-shadow: 0 5px 2px rgba(52, 58, 64, 0.2)

}

.slider-primary .slick-dots li button {
    background: #4E97FD
}

.slider-primary.slick-slider .slick-prev, .slider-primary.slick-slider .slick-next {
    background-color: rgba(78, 151, 253, 0.1);
    color: #4E97FD
}

.slider-secondary .slick-dots li button {
    background: #6c757d
}

.slider-secondary.slick-slider .slick-prev, .slider-secondary.slick-slider .slick-next {
    background-color: rgba(108, 117, 125, 0.1);
    color: #6c757d
}

.slider-success .slick-dots li button {
    background: #28a745
}

.slider-success.slick-slider .slick-prev, .slider-success.slick-slider .slick-next {
    background-color: rgba(40, 167, 69, 0.1);
    color: #28a745
}

.slider-info .slick-dots li button {
    background: #17a2b8
}

.slider-info.slick-slider .slick-prev, .slider-info.slick-slider .slick-next {
    background-color: rgba(23, 162, 184, 0.1);
    color: #17a2b8
}

.slider-warning .slick-dots li button {
    background: #ffc107
}

.slider-warning.slick-slider .slick-prev, .slider-warning.slick-slider .slick-next {
    background-color: rgba(255, 193, 7, 0.1);
    color: #ffc107
}

.slider-danger .slick-dots li button {
    background: #dc3545
}

.slider-danger.slick-slider .slick-prev, .slider-danger.slick-slider .slick-next {
    background-color: rgba(220, 53, 69, 0.1);
    color: #dc3545
}

.slider-light .slick-dots li button {
    background: #edf1fc
}

.slider-light.slick-slider .slick-prev, .slider-light.slick-slider .slick-next {
    background-color: rgba(237, 241, 252, 0.1);
    color: #edf1fc
}

.slider-dark .slick-dots li button {
    background: #343a40
}

.slider-dark.slick-slider .slick-prev, .slider-dark.slick-slider .slick-next {
    background-color: rgba(52, 58, 64, 0.1);
    color: #343a40
}

.slider-primary-2 .slick-dots li button {
    background: #FFD55A
}

.slider-primary-2.slick-slider .slick-prev, .slider-primary-2.slick-slider .slick-next {
    background-color: rgba(255, 213, 90, 0.1);
    color: #FFD55A
}

.banner-heading {
    position: relative;
    text-shadow: 3px 0px 0px rgba(0, 0, 0, 1);
}

.slider .slick-list {
    padding-right: 270px;
}

.slider .slick-list .slick-track .slick-slide:not(.slick-active) {
    opacity: 0.3;
}

.banner-view .slider .slick-list .slick-track .slick-slide:not(.slick-active) {
    opacity: 1;
}

.banner-view .slider .slick-list {
    padding-right: 0;
}

.banner-view .slider::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 50px;
    background: linear-gradient(167deg, rgba(0, 0, 0, 0.70) 9.13%, rgba(0, 0, 0, 0.00) 60%);
    z-index: 1;
}

.banner-text-mobile {
    display: none;
}

@media (min-width: 800px) and (max-width: 1000px) {
    .banner-view {
        padding: 0 40px 0 40px;
    }

    .banner-text {
        margin-top: 40px;
        font-size: 30.997px;
    }
}

@media (min-width: 600px) and (max-width: 800px) {
    .banner-view {
        padding: 0 25px 0 25px;
    }

    .banner-text {
        margin-top: 40px;
        font-size: 17.997px;
    }
}

@media (max-width: 600px) {

    .banner-view {
        position: relative;
        max-width: 100%;
        padding: 0 11px 0 11px;
        flex-direction: column;
        align-items: center;
        top: -20px;
    }

    .banner-view .slider::before {
        border-radius: 20px;
    }

    .slider .slider-img {
        border-radius: 25px;
        background: #FFF;
        /* height: 191px; */
    }


    .slider .slick-list {
        padding-right: 0;
    }

    .slider .slick-list .slick-track .slick-slide:not(.slick-active) {
        opacity: unset
    }

    .banner-text {
        display: none;
    }

    .slider {
        width: 100%;
    }

    .banner-text-mobile {
        display: block;
        margin-right: 0;
        text-align: center;
    }


    .banner-text-mobile {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        text-align: center;
        width: 100%;
        z-index: 2; /* Ensure it's above the slider */
        color: white; /* Text color */
        color: #F2BC1A;
        font-family: Orbitron;
        font-style: normal;
        font-weight: 500;
        letter-spacing: 5.3px;
    }

    .slider .slick-prev {
        left: 13px;
        color: #9E1F32;
        font-weight: bold;
    }

    .banner-heading-mobile {
        margin-top: -60px;
        font-size: 15px;
        color: white !important;
    }

    .slider-mob.arrow-light .slick-prev, .slider.arrow-light .slick-next {
        border: 0px solid #edf2f9;
        -webkit-box-shadow: 0 5px 2px rgba(52, 58, 64, 0.2);
        box-shadow: 0 5px 2px rgba(52, 58, 64, 0.2);
        display: none !important;
    }

    .slider-mob.arrow-light .slick-prev, .slider.arrow-light .slick-prev {
        border: 0px solid #edf2f9;
        -webkit-box-shadow: 0 5px 2px rgba(52, 58, 64, 0.2);
        box-shadow: 0 5px 2px rgba(52, 58, 64, 0.2);
        display: none !important;
    }

    .slider-primary .slick-dots li button {
        background: #4E97FD
    }

    .slider-mob.slider-primary.slick-slider .slick-prev, .slider-primary.slick-slider .slick-next {
        background-color: rgba(78, 151, 253, 0.1);
        color: #4E97FD;
    }

    .sub-sliders {
        width: 300px !important;
    }

    .slider .slick-next {
        right: 13px;
        color: #9E1F32 !important;
        font-weight: bold;
    }

    .slider .slick-next:before {
        content: "\e649";
        display: block;
        margin: 0 auto;
        font-size: 17px
    }

    .slider.arrow-circle .slick-prev, .slider.arrow-circle .slick-next {
        border-radius: 50%;
        height: 48px;
        width: 48px
    }

}