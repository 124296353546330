/*
* Header styles
* for desktop
* and mobile
*/
.header {
    position: relative;
}

.header .navbar-nav {
    gap: 37px;
}

.header .nav-link {
    font-size: 20px;
    display: flex;
    align-items: center;
    color: #fff !important;
    line-height: 26.224px;
}

.header .nav-link text-white img {
    margin-top: -5px;
}
.profile-imge-wrapper{
    background-image: url('../images/profile/profile-background.png');
    background-repeat: no-repeat;
    padding-bottom: 23px;
}

.header .title_user_id {
    display: none;
}

.header .active, .nav-item a:hover {
    color: var(--primary) !important;
    filter: invert(59%) sepia(89%) saturate(391%) hue-rotate(35deg) brightness(91%) contrast(94%);
}

.mobileMenuLinks ul li a.menue-active{
    color: var(--primary) !important;
    filter: invert(59%) sepia(89%) saturate(391%) hue-rotate(35deg) brightness(91%) contrast(94%);
}

.header .menu-sidebar {
    margin-top: 0;
    margin-bottom: 0;
    padding-top: 60px;
}

.header .mobile-header-button {
    display: flex;
    padding: 12px 12px;
    justify-content: center;
    align-items: center;
    gap: 8.345px;
    border-radius: 13.353px;
    text-decoration: none;
    color: white;
    background: linear-gradient(94deg,#ff6601 4.28%,#794444f7 109.53%);
}

.header .navbar-desktop-img {
    margin-left: 60px;
    /* width:100%;
    height:100px; */
}

.header .header-logo {
    width: 100%;
    height: 100px;
}

.header .mobile-header-button:hover {
    background: var(--primary) !important;
    color: #fff !important;
}

.header .desktop-header-button {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    gap: 8.345px;
    border-radius: 13.353px;
    text-decoration: none;
    color: white;
    margin-right: 85px;
    float: right;
    background: linear-gradient(94deg,#ff6601 4.28%,#794444f7 109.53%);
}

.header .desktop-header-button:hover {
    background: var(--primary) !important;
    color: #fff !important;
}

.header .mobile-header {
    display: none;
}

.header .mobile {
    display: none;
}

.header .banner-text-mobile {
    display: none;
}

.header .desktop-header-profile-img {
    justify-content: center;
    align-items: center;
    gap: 8.345px;
    border-radius: 13.353px;
    text-decoration: none;
    margin-right: 50px;
    float: right;
    border-radius: 25.38px;

    margin-left: 16px;
    margin-top: 10px;
}

.header .desktop-header-profile-img-profile {
    justify-content: center;
    align-items: center;
    gap: 8.345px;
    border-radius: 13.353px;
    text-decoration: none;
    margin-right: 85px;
    float: right;
    border-radius: 25.38px;
}

.header .navbar-collapse {
    justify-content: center;
    text-align: center;
}

.header .navbar-collapse img {
    margin-right: 10px;
}

.header .notification-icon-mobile {
    padding-right: 30px;
}

.header .notification-icon-desktop {
    padding-right: 50px;
    display: block;
}

.header .notification-icon-mobile {
    display: none;
}

.header .profile {
    background-image: url('../images/profile/profile-background.png');
    background-repeat: no-repeat;
    padding-bottom: 23px;
    padding-right: 39px;
}

@media (min-width: 941px) and (max-width: 1203px) {

    .header .notification-icon-desktop {
        padding-right: 25px;
    }

    .header .navbar-desktop-img {
        margin-left: 15px;
    }

    .header .desktop-header-button {
        margin-right: 15px;
    }
    .header .profile {
        padding-right: 0px
    }

    .header .navbar-nav {
        gap: 15px;
    }

    .header .nav-link {
        font-size: 16px;
    }
}

@media (min-width: 870px) and (max-width: 941px) {
    .header .notification-icon-desktop {
        padding-right: 20px;
    }
    .header .desktop-header-button {
        margin-right: 10px;
    }
    .header .navbar-desktop-img {
        margin-left: 10px;
    }

    .header .profile {
        padding-right: 0px
    }

    .header .navbar-nav {
        gap: 13px;
    }

    .header .nav-link {
        font-size: 14px;
    }
}


@media (min-width: 774px) and (max-width: 929px) {
    .header .notification-icon-desktop {
        padding-right: 10px;
    }

    .header .desktop-header-button {
        margin-right: 0px;
    }

    .header .navbar-desktop-img {
        margin-left: 0;
    }

    .header .profile {
        padding-right: 0;
    }

    .header .navbar-nav {
        gap: 12px;
    }

    .header .nav-link {
        font-size: 10px;
    }
}


@media (min-width: 576px) and (max-width: 774px) {
    .header .notification-icon-desktop {
        padding-right: 10px;
    }

    .header .desktop-header-button {
        margin-right: 0;
    }

    .header .navbar-desktop-img {
        margin-left: 0;
    }

    .header .profile {
        padding-right: 0;
    }

    .header .navbar-nav {
        gap: 5px;
    }

    .header .nav-link {
        font-size: 10px;
    }

    .header .nav-item img {
        display: none;
    }
}

@media (max-width: 576px) {
    .header .navbar-desktop-img {
        margin-left: 0;
    }

    .header .notification-icon-mobile {
        display: block;
    }

    .header .notification-icon-desktop {
        display: none;
    }

    .header .profile {
        display: none;
    }

    .header .desktop-header-button {
        margin-right: 12px;
    }

    .header .navbar {
        padding: 0;
    }

    .header .header-logo {
        width: 100%;

    }

    .header .mobile-nav {
        text-align: left !important;
    }

    .header .navbar-collapse .collapse .show {
        width: 286px;
        height: 100% !important;
        padding-bottom: 658px;
        --bs-bg-opacity: 1;
    }

    .header .container {
        position: relative;
        display: inline-block;
    }

    .header .user-profile-image-sidebar {
        border-radius: 50px;
        width: 50px;
        height: 50px;
        margin-left: 20px;
        margin-top: 10px;
    }

    .header .title_user_id {
        display: none;
    }

    .header .profile-text-sidebar {
        position: absolute;
        top: 50%;
        left: 61%;
        transform: translate(-50%, -50%);
        color: white;
        text-align: center;
        line-height: 0.5px;
    }

    .header .profile-edit-sidebar {
        position: absolute;
        top: 50%;
        left: 87%;
        transform: translate(-50%, -50%);
        color: white;
        text-align: center;
        width: 14px;
        height: 34.542px;
        flex-shrink: 0;
    }

    .header .profile-userid-sidebar {
        color: var(--ios-home-indicator-background-color, #FFF);
        font-family: Poppins;
        font-size: 10.219px;
        font-style: normal;
        font-weight: 300;
    }

    .header .profile-username-sidebar {
        color: var(--ios-home-indicator-background-color, #FFF);
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
    }

    .header .unsubscribe-button-sidebar {
        width: 75%;
        display: flex;
        text-align: center;
        font-size: 12px;
        justify-content: center;
        padding: 12px 68px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 16px;
        background: linear-gradient(94deg,#ff6601 4.28%,#794444f7 109.53%);
        color: white !important;
        margin: 0 auto;
        margin-top: 15px;
        cursor: pointer;
        /* position: relative;
        top: 50px;
        bottom: 48px;
        left: 18px; */
    }

    .header .subscribe-button-sidebar {
        width: 75%;
        display: flex;
        text-align: center;
        font-size: 12px;
        justify-content: center;
        padding: 12px 68px;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        border-radius: 16px;
        background: linear-gradient(94deg,#ff6601 4.28%,#794444f7 109.53%);
        color: white !important;
        margin: 0 auto;
        margin-top: 15px;
        cursor: pointer;
        /* position: fixed;
        bottom: 48px;
        left: 18px; */
    }

    .header .subscribe-button-sidebar:hover {
        color: #fff !important;
        background: #F2BC1A !important;
    }

    .header .subscribe-button-sidebar a {
        color: white !important;
        font-size: 14px;
        font-weight: 400;
    }

    .header .menue-active {
        color: var(--primary);
        border-right: 4px solid var(--primary);
    }

    .header .mobile-header {
        overflow-x: hidden;
        display: block;
    }

    .header .mobile-sliders {
        display: block;
    }

    .header .mobile-header ul {
        list-style: none;
        padding: 0;
        margin: 0
    }

    .header .mobile-header .header-logo {
        width: auto
    }

    .header .mobile-header .header-right-options .link-item {
        position: relative
    }

    .header .mobile-header .header-right-options .link-item .badge {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        z-index: 2;
        position: absolute;
        right: 4px;
        top: -8px;
        height: 18px;
        min-width: 18px;
        border-radius: 27px;
        background: #4E97FD;
        padding: 0;
        left: auto
    }

    .header .mobile-header .overlay {
        width: 100%;
        top: 0;
        left: 0;
        height: 100%;
        background: rgba(0, 0, 0, 0.8);
        z-index: 123;
        position: absolute;
        visibility: hidden;
        opacity: 0
    }

    .header .mobile-header .overlay.show {
        opacity: 1;
        visibility: visible;
        -webkit-transition: visibility 0.5s, opacity 0.5s cubic-bezier(0.3, 0, 0.3, 1);
        -o-transition: visibility 0.5s, opacity 0.5s cubic-bezier(0.3, 0, 0.3, 1);
        transition: visibility 0.5s, opacity 0.5s cubic-bezier(0.3, 0, 0.3, 1)
    }

    .header .mobile-header .menu-sidebar {
        position: fixed;
        width: 250px;
        background: #1A1A1A;;
        height: 100%;
        top: 0;
        left: -100px;
        visibility: hidden;
        opacity: 0;
        z-index: 999;
        font-family: "Poppins", sans-serif;
        -webkit-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        margin-top: 0;
        margin-bottom: 0;
        padding-top: 10px;
        overflow: scroll;
        overflow-x: hidden;

    }

    .header .mobile-header .menu-sidebar .close {
        position: relative;
        left: 210px;
        top: 0px;
        font-size: 16px;
        color: #ffffff;
        opacity: 1
    }

    .header .mobile-header .menu-sidebar .welcome {
        margin-top: 0px;
        padding: 16px 20px 16px 20px;
        font-size: 14px;
    }

    .header .mobile-header .menu-sidebar .welcome .btn:first-child {
        margin-right: 10px
    }

    .header .mobile-header .menu-sidebar .welcome .avater img {
        width: 100%;
        height: auto
    }

    .header .mobile-header .menu-sidebar .welcome span {
        font-weight: 600
    }

    .header .mobile-header .menu-sidebar .mobileMenuLinks ul li a {
        margin-top: 20px;
        color: #d9d9d9 !important;
        padding: 10px 20px;
        display: block;
        font-weight: 400;
        font-family: Poppins;
        letter-spacing: 0.5px;
        word-spacing: 2px;
        font-size: 16px;
        position: relative;
        opacity: 0.8
    }

    .header .mobile-header .menu-sidebar .mobileMenuLinks h6 {
        padding: 10px 20px;
        color: #D9D9D9;
        font-weight: bold;
        font-size: 14px
    }

    .header .mobile-header .menu-sidebar.show {
        left: 0px;
        visibility: visible;
        opacity: 1
    }
}
