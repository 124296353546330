:root {
    --blue: #007bff;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #fd7e14;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #D9D9D9;
    --gray: #6c757d;
    --gray-dark: #343a40;
    --primary: #8cb517;
    --secondary: #6c757d;
    --success: #28a745;
    --info: #17a2b8;
    --warning: #ffc107;
    --danger: #dc3545;
    --light: #edf1fc;
    --dark: #343a40;
    --primary-2: #FFD55A;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
}

@font-face {
    font-family: 'Orbitron';
    src: url('../fonts/Orbitron/static/Orbitron-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins';
    src: url('../fonts/Poppins/Poppins-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Urbanist';
    src: url('../fonts/Urbanist/static/Urbanist-Regular.ttf') format('truetype');
}

*, *::before, *::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: "Nunito", sans-serif;
    color: #343a40;
    font-size: 15px;
    font-weight: 400;
    margin: 0;
    line-height: 1.5;
    color: #212529;
    background-color: #1A1A1A !important;
    overflow-x: hidden;
}

body a {
    color: #8cb517 ;
    text-decoration: none;
    background-color: transparent
}
.active{
    color: #8cb517  !important;
}



/* subscribe model start */

.subscribe-modal {
position: fixed;
top: 0;
left: 0px;
z-index: 1060;
display: none;
width: 100%;
height: 100%;
overflow-x: hidden;
overflow-y: auto;
outline: 0;
border-radius: 20px;

}

.subscribe-modal-dialog {
max-width: 362px !important;
margin: 1.75rem auto;
display: flex !important;
opacity: 1;
visibility: visible;
}



@media (min-width: 992px){
.subscribe-modal-dialog {
    max-width: 260px;
    margin: 1.75rem auto;
}
}

@media (max-width: 991.98px) {
    .subscribe-modal-dialog {
        align-items: center;
        justify-content: center;
        min-height: calc(100% - 1rem);
    }
}

.subscribe-modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    border-radius: 66.529px;
    background: var(--dark-dark-2, #1F222A);
    background-clip: padding-box;
    border: 1px solid rgba(0,0,0,.2);
    outline: 0;
    text-align: center;
    justify-content: center;
}


.purchase-btn{
  margin-top: -6px;
  display: flex;
  font-size: 12.167px;
  padding: 8.023px 12px;
  justify-content: center;
  align-items: center;
  gap: 13.023px;
  top:-20px;
  color: white !important;
  border-radius: 8.837px;
  flex-shrink: 0;background: linear-gradient(94deg, #D83335 4.28%, rgba(121, 68, 68, 0.97) 109.53%);
  }
.card-heading-challenge{
    font-size: 12px;
}
.subscribe-modal{
    padding: 1.5rem;
}

.subscribe-modal-icon{
    width: 52%;
    height: 155.397px;
    flex-shrink: 0;
}
.rewards-modal-icon{
    width: 100%;
    height: 236.397px;
    margin-top: -6px;
}
.subscribe-info-heading-modal{
    color: #F2BC1A !important;
    font-size: 22.167px;
}

.subscribe-heading-modal {
    margin-top: -7px;
    color: #fff;
    font-family: Urbanist;
    font-style: normal;
    font-size: 15.167px;
    font-style: normal;
    font-weight: 400;
    padding-top: 15px;
    line-height: 140%;
    letter-spacing: 0.2px;
}
.subscribe-modal-body {
    position: relative;
    flex: 1 1 auto;
    padding: 2rem;
}

.subscribe-modal-body img{
    flex-shrink: 0;
    width: 125.397px;
    height: 126.397px;
}
.subscribe-modal-body-back-img{
    align-items: center;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    height: 223px;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
    width: 185.93px;
    background-size: 100%;
}
.notification-back-img{
    background-image: url('../images/icons/notification-back.png');
}
.subscribe-btn-modal{
    margin-top: 20px;
    display: flex;
    font-size: 13.167px;
    padding: 18.023px 12px;
    justify-content: center;
    align-items: center;
    gap: 13.023px;
    top:-20px;
    color: white !important;
    border-radius: 14.837px;
    flex-shrink: 0;
    background: linear-gradient(94deg, #D83335 4.28%, rgba(121, 68, 68, 0.97) 109.53%);
    }

  .unsubscribe-btn-modal{
      margin-bottom: -10px;
    margin-top: 10px;
    display: flex;
    font-size: 13.167px;
    padding: 18.023px 12px;
    justify-content: center;
    align-items: center;
    gap: 13.023px;
    top:-20px;
    background: var(--Secondary-red, linear-gradient(94deg, #FFA2A3 1.09%, rgba(195, 187, 187, 0.97) 109.51%));
    color: rgba(121, 68, 68, 0.97) !important;
    border-radius: 14.837px;
    flex-shrink: 0;
  }




/* subscribe model end */