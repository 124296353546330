.detail{
    color: #FFF;
    font-size: 25px;
    font-style: normal;
    font-weight: 400;
    line-height: 50%;
    letter-spacing: 0.246px;
}
.w-160{
    width: 165px !important;
    float: left;
}
.detail span{
    color: #F2BC1A;
}
.tournament-detail-banner-img{
    border-radius: 25px;
    width: 100%;
}
.play-now-card-slider-btn{
    float: left;
}
.watch-video-link {
    color: #fff;
}


@media (max-width: 992px) {
    .detail{
        font-size: 18px;
    }
}